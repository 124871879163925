import React from "react";
import { Link } from "react-router-dom";

function Privacy() {
  return (
    <div className="bg-black p-2 mx-auto w-full lg:text-[0.8vw] text-sm">
      <p className="text-white text-center mb-4">
        <Link className="text-yellow-400 underline">Gizlilik koşulları</Link> ve{" "}
        <Link className="text-yellow-400 underline">hükümler</Link> için
        tıklayın.
      </p>
    </div>
  );
}

export default Privacy;
