import React, { useState, useEffect } from "react";
import logo from "../images/tovivu_main_logo_transparent.png";
import playStore from "../images/get-it-on-google-play.png";
import appStore from "../images/get-it-on-app-store.svg";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import cities from "../city.json";
import axios from "axios";

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const backendUrlNoS = process.env.REACT_APP_BACKEND_URLNOS;

function TitleArea({
  setCurrentLevel,
  name,
  setName,
  setSurname,
  surname,
  email,
  setEmail,
  address,
  setAddress,
  city,
  setCity,
  idNumber,
  setIdNumber,
  phoneNumber,
  setPhoneNumber,
  setDiscountAmount,
  discountAmount,
  setCurrentPacket,
  setVerifyCode,
  verifyCode,
  setDiscountCode,
  discountCode,
}) {
  const [notValidUser, setNotValidUser] = useState(false);

  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [err, setErr] = useState("");
  const [showErr, setShowErr] = useState(false);
  const [sendCode, setSendCode] = useState(false);
  // New fields

  const formatPhoneNumber = (value) => {
    const cleanedValue = value.replace(/\D/g, "");
    const match = cleanedValue.match(/^(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
      return `${match[1]} ${match[2]} ${match[3]} ${match[4]}`;
    }
    return cleanedValue;
  };

  const handlePhoneChange = (e) => {
    const input = e.target.value.replace(/\D/g, "").slice(0, 10);
    setPhoneNumber(formatPhoneNumber(input));
  };

  const handlePhoneBlur = (e) => {
    const input = e.target.value.replace(/\D/g, "").slice(0, 10);
    setPhoneNumber(formatPhoneNumber(input));
  };
  const checkVerifyCode = async () => {
    const rawPhoneNumber = phoneNumber.replace(/\s/g, "");
    if (verifyCode === "") {
      setErr("Lütfen doğrulama kodunu giriniz.");
      setShowErr(true);
    } else {
      try {
        const response = await axios.get(
          `${backendUrl}/tel-verify?phone_number=${rawPhoneNumber}&verifcation_code=${verifyCode}`
        );
        if (response.data.data.status) {
          setIsPhoneVerified(true);
        } else {
          setErr("Doğrulama kodu hatalı.");
          setShowErr(true);
        }
      } catch (error) {
        console.error("Error verifying phone number:", error);
        setErr("Telefon numarası doğrulama sırasında bir hata oluştu.");
        setShowErr(true);
      }
    }
  };
  const verifyPhoneNumber = async () => {
    const rawPhoneNumber = phoneNumber.replace(/\s/g, "");
    if (rawPhoneNumber.length < 10) {
      setErr("Lütfen geçerli bir telefon numarası girin.");
      setShowErr(true);
    } else {
      try {
        const response = await axios.post(
          `${backendUrl}/user/start-tel-verify`,
          {
            phone_number: rawPhoneNumber,
          }
        );
        setSendCode(true);
      } catch (error) {
        console.error("Error verifying phone number:", error);

        setErr(
          "Bu numara sistemimizde kayıtlı değil 😔. Üye olduktan sonra paket satın alma işlemine devam edebilirsin."
        );
        setShowErr(true);
      }
    }
  };
  const [nameError, setNameError] = useState("");
  const [surnameError, setSurnameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [cityError, setCityError] = useState("");
  const handleApplyDiscount = async () => {
    let valid = true;
    if (!name) {
      setNameError("Ad doldurulması zorunludur.");
      valid = false;
    }
    if (!email) {
      setEmailError("E-mail doldurulması zorunludur.");
      valid = false;
    }
    if (!address) {
      setAddressError("Adres doldurulması zorunludur.");
      valid = false;
    }
    if (!city) {
      setCityError("Şehir seçilmesi zorunludur.");
      valid = false;
    }
    if (!surname) {
      setSurnameError("Soy Ad doldurulması zorunludur.");
      valid = false;
    }

    if (valid) {
      try {
        const response = await axios.get(
          `${backendUrl}/check-coupon-status?coupon_name=${discountCode}`
        );
        const responseCode = response.data.data;

        if (responseCode.type !== null) {
          const currentDate = new Date().toString();
          const expireDate = Date(responseCode.expire_date).toString();
          if (!responseCode.useable) {
            setErr("Üzgünüz bu kupon aktif değil 😔");
            setShowErr(true);
          } else if (expireDate < currentDate) {
            setErr("Üzgünüz kupon süresi geçti 😔");
            setShowErr(true);
          } else {
            if (responseCode.type === "yuzde") {
              setDiscountAmount({
                discount: responseCode.discount,
                type: "yuzde",
                packets: responseCode.packets,
              });
              setShowErr(true);
              setErr("Kupon Başarılı bir şekilde uygulandı");
              Swal.fire({
                title: "Başarılı",
                text: "İndirim kodunuz başarılı bir şekilde uygulandı fiyatları yeniden kontrol et 😉.",
                icon: "success",
                showConfirmButton: false,
                timer: 4000,
              });
            } else if (responseCode.type === "miktar") {
              setDiscountAmount({
                discount: responseCode.discount,
                type: "miktar",
                packets: responseCode.packets,
              });
              setShowErr(true);
              setErr("Kupon Başarılı bir şekilde uygulandı");
              Swal.fire({
                title: "Başarılı",
                text: "İndirim kodunuz başarılı bir şekilde uygulandı fiyatları yeniden kontrol et 😉.",
                icon: "success",
                showConfirmButton: false,
                timer: 4000,
              });
            }
            setErr("");
            setShowErr(false);
          }
        } else {
          setErr("Üzgünüz bu şekilde bir kupon yok 😔");
          setShowErr(true);
        }
      } catch (error) {
        console.error("Error fetching packet data:", error);
      }
    }
  };

  const checkUserPacketStatus = async (phoneNumber) => {
    const rawPhoneNumber = phoneNumber.replace(/\s/g, "");
    try {
      const response = await axios.get(
        `${backendUrl}/check-user-packet-status?phone=${rawPhoneNumber}&country_code=90`
      );
      const { current_level, new_user, packet_id } = response.data.data;
      setCurrentPacket(packet_id);
      setCurrentLevel(current_level);
    } catch (error) {
      console.error("Error fetching user packet status:", error);
    }
  };

  useEffect(() => {
    if (phoneNumber.length === 13) {
      checkUserPacketStatus(phoneNumber);
    }
  }, [phoneNumber]);

  useEffect(() => {
    let timer;
    if (showErr) {
      timer = setTimeout(() => {
        setShowErr(false);
      }, 6000);
    }
    return () => clearTimeout(timer);
  }, [showErr]);
  const handleDiscountCodeChange = (e) => {
    setDiscountAmount("");
    setDiscountCode(e.target.value);
  };
  const handleFieldChange = (setter, errorSetter) => (e) => {
    setter(e.target.value);
    errorSetter("");
  };
  return (
    <>
      <div>
        <div className="logoArea w-full flex items-center justify-center">
          <img src={logo} className="w-44 lg:w-[13vw]" alt="Logo" />
        </div>
        <div className="marketArea w-full flex items-center justify-center">
          <Link
            to="https://play.google.com/store/apps/details?id=com.tovivu.client.app&hl=tr"
            target="_blank"
          >
            <img
              src={playStore}
              className="w-36 lg:w-[13vw]"
              alt="Play Store"
            />
          </Link>
          <Link
            to="https://apps.apple.com/tr/app/tovivu/id6477754512"
            target="_blank"
          >
            <img
              src={appStore}
              className="w-36 lg:w-[13vw] ml-4 lg:ml-8"
              alt="App Store"
            />
          </Link>
        </div>
        <div className="textArea w-full flex flex-col items-center justify-center">
          <h1 className="lg:text-[1.2vw] text-white mt-16 text-center text-sm px-4">
            Tovivu'nun sınırsız dünyasını avantajlı paketlerle keşfet!
          </h1>
          {!isPhoneVerified && (
            <>
              <h1 className="lg:text-[1.2vw] text-white my-8 text-center text-sm px-4">
                Lütfen sınırsız pakete yükselteceğiniz Tovivu hesabına ait
                telefon numarasını girin:
              </h1>
              <div className="inputArea w-full flex flex-col items-center justify-center lg:text-[0.9vw] text-sm">
                <div className="relative flex items-center w-[75%] lg:w-[30%] mb-4">
                  <span className="absolute left-2 lg:left-4 lg:top-[13px] top-[9px] text-yellow-400">
                    &#9888;
                  </span>
                  <span className="absolute left-[28px] lg:left-[42px] text-yellow-400 lg:top-[13px] top-[9px]">
                    +90
                  </span>
                  <input
                    type="tel"
                    value={formatPhoneNumber(phoneNumber)}
                    onChange={handlePhoneChange}
                    onBlur={handlePhoneBlur}
                    className="w-full px-[58px] lg:px-20 py-2 lg:py-3 bg-[#2f2f2e] text-gray-400 rounded-full border border-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                    placeholder="Telefon numarasını girin"
                    maxLength={12}
                  />
                </div>
                {sendCode && (
                  <>
                    <div className="relative flex items-center w-[75%] lg:w-[30%] mb-4">
                      <span className="absolute left-2 lg:left-4 lg:top-[13px] top-[9px] text-yellow-400">
                        &#9888;
                      </span>
                      <input
                        value={verifyCode}
                        onChange={(e) => setVerifyCode(e.target.value)}
                        type="text"
                        className="w-full px-[28px] lg:px-12 py-2 lg:py-3 bg-[#2f2f2e] text-gray-400 rounded-full border border-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                        placeholder="Telefon numarasına gelen kodu girin"
                      />
                    </div>
                    <button
                      onClick={checkVerifyCode}
                      className="p-2 lg:p-3 border border-yellow-400 rounded-full text-white ml-4 cursor-pointer hover:bg-yellow-400 hover:text-gray-800 transition-all duration-400"
                    >
                      Telefonu Doğrula
                    </button>
                  </>
                )}
                {!sendCode && (
                  <button
                    onClick={verifyPhoneNumber}
                    className="p-2 lg:p-3 border border-yellow-400 rounded-full text-white ml-4 cursor-pointer hover:bg-yellow-400 hover:text-gray-800 transition-all duration-400"
                  >
                    Kod Gönder
                  </button>
                )}
              </div>
            </>
          )}
        </div>

        {isPhoneVerified && (
          <>
            <div className="textArea w-full flex flex-col items-center justify-center">
              <h1 className="lg:text-[1vw] text-white my-8 text-center text-sm px-4">
                Telefon numarası doğrulandı! Lütfen aşağıdaki bilgileri
                doldurun:
              </h1>
            </div>

            <div className="inputArea w-full flex flex-col items-center justify-center lg:text-[0.9vw] text-sm mb-8">
              <div className="relative flex items-center w-[75%] lg:w-[30%] mb-4">
                <input
                  type="text"
                  value={name}
                  onChange={handleFieldChange(setName, setNameError)}
                  className="w-full px-8 lg:px-12 py-2 lg:py-3 bg-[#2f2f2e] text-gray-400 rounded-full border border-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                  placeholder="Ad"
                  required
                />
              </div>
              {nameError && (
                <div className="w-full flex items-center justify-center mb-4">
                  <p className="lg:text-[1.2vw] text-yellow-400 text-xs">
                    {nameError}
                  </p>
                </div>
              )}
              <div className="relative flex items-center w-[75%] lg:w-[30%] mb-4">
                <input
                  type="text"
                  value={surname}
                  onChange={handleFieldChange(setSurname, setSurnameError)}
                  className="w-full px-8 lg:px-12 py-2 lg:py-3 bg-[#2f2f2e] text-gray-400 rounded-full border border-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                  placeholder="Soyad"
                  required
                />
              </div>
              {surnameError && (
                <div className="w-full flex items-center justify-center mb-4">
                  <p className="lg:text-[1.2vw] text-yellow-400 text-xs">
                    {surnameError}
                  </p>
                </div>
              )}
              <div className="relative flex items-center w-[75%] lg:w-[30%] mb-4">
                <input
                  type="email"
                  value={email}
                  onChange={handleFieldChange(setEmail, setEmailError)}
                  className="w-full px-8 lg:px-12 py-2 lg:py-3 bg-[#2f2f2e] text-gray-400 rounded-full border border-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                  placeholder="E-posta"
                  required
                />
              </div>
              {emailError && (
                <div className="w-full flex items-center justify-center mb-4">
                  <p className="lg:text-[1.2vw] text-yellow-400 text-xs">
                    {emailError}
                  </p>
                </div>
              )}
              <div className="relative flex items-center w-[75%] lg:w-[30%] mb-4">
                <input
                  type="text"
                  value={address}
                  onChange={handleFieldChange(setAddress, setAddressError)}
                  className="w-full px-8 lg:px-12 py-2 lg:py-3 bg-[#2f2f2e] text-gray-400 rounded-full border border-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                  placeholder="Adres"
                  required
                />
              </div>
              {addressError && (
                <div className="w-full flex items-center justify-center mb-4">
                  <p className="lg:text-[1.2vw] text-yellow-400 text-xs">
                    {addressError}
                  </p>
                </div>
              )}
              <div className="relative flex items-center w-[75%] lg:w-[30%] mb-4">
                <select
                  value={city}
                  onChange={handleFieldChange(setCity, setCityError)}
                  className="w-full px-8 lg:px-12 py-2 lg:py-3 bg-[#2f2f2e] text-gray-400 rounded-full border border-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                  required
                >
                  <option value="">Şehir Seçiniz</option>
                  {Object.entries(cities).map(([key, city]) => (
                    <option key={key} value={city}>
                      {city}
                    </option>
                  ))}
                </select>
              </div>
              {cityError && (
                <div className="w-full flex items-center justify-center mb-4">
                  <p className="lg:text-[1.2vw] text-yellow-400 text-xs">
                    {cityError}
                  </p>
                </div>
              )}
              <div className="relative flex items-center w-[75%] lg:w-[30%] mb-2">
                <input
                  type="text"
                  value={idNumber}
                  onChange={(e) => setIdNumber(e.target.value)}
                  className="w-full px-8 lg:px-12 py-2 lg:py-3 bg-[#2f2f2e] text-gray-400 rounded-full border border-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                  placeholder="TC Kimlik No (opsiyonel)"
                />
              </div>
              <div className="relative flex items-center w-[75%] lg:w-[30%] mt-2">
                <span className="absolute left-2 lg:left-4 text-yellow-400">
                  &#128179;
                </span>
                <input
                  type="text"
                  value={discountCode}
                  onChange={handleDiscountCodeChange}
                  className="w-full px-8 lg:px-12 py-2 lg:py-3 bg-[#2f2f2e] text-gray-400 rounded-full border border-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                  placeholder="İndirim kodu (opsiyonel)"
                />
                <button
                  onClick={handleApplyDiscount}
                  className="p-2 lg:p-3 border border-yellow-400 rounded-full text-white ml-4 cursor-pointer hover:bg-yellow-400 hover:text-gray-800 transition-all duration-400"
                >
                  Uygula
                </button>
              </div>
            </div>
          </>
        )}

        {showErr && (
          <div className="w-full flex items-center justify-center px-5 my-4">
            <p className="lg:text-[1vw] text-yellow-400 text-sm text-center">
              {err}
            </p>
          </div>
        )}
      </div>
    </>
  );
}

export default TitleArea;
